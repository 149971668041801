import * as React from "react";
import Autocomplete from "@material-ui/lab/Autocomplete/Autocomplete";
import { createFilterOptions } from '@material-ui/lab/Autocomplete';
import TextField from "@material-ui/core/TextField/TextField";
import CircularProgress from "@material-ui/core/CircularProgress/CircularProgress";

const filterOptions = createFilterOptions({
    trim: true,
    ignoreCase: true,
});

export default class AsyncSelect extends React.Component {
    state = {
        options: [],
        loaded: false,
        open: false,
        inputValue: ''
    };

    componentDidMount() {
        const {reFilterOnChange} = this.props;
        if(!reFilterOnChange) this.loadData();
        else this.setState({loaded: true });
    }

    loadData = async (param=null) => {
        //const {loaded} = this.state;
        const {method, parser} = this.props;
        const response = await fetch(`${process.env.REACT_APP_API_URL}${method}${param ? `/${encodeURI(param)}` : ''}`);
        const result = await response.json();

        if(result.data) {
            const parsed = parser(result.data);
            this.setState({ options: parsed, loaded: true });
        } else this.setState({loaded: true });

    };

    handleReloadChange = async e => {
        if(e.target.value.length >= 4) {
            this.setState({loaded: false});
            this.loadData(e.target.value)
        }

        this.setState({inputValue: e.target.value})
    };

    setOpen = open => this.setState({open});

    render() {
        const {open, loaded, options, inputValue} = this.state;
        const {showError, error, onChange, className, value, param, label, helperText, filter, reFilterOnChange, parser, ...rest} = this.props;

        if(reFilterOnChange) {
            if(param && !options.length)
                this.loadData(param);

            if(!inputValue && !value && options.length > 0)
                this.setState({ options: [] });
        }

        if(!value && param) {
            const f = options.find(k => k[filter] === param);
            if(f) onChange(null, f);
        }

        return <Autocomplete
            open={open}
            onOpen={() => {
                this.setOpen(true);
            }}
            onClose={() => {
                this.setOpen(false);
            }}
            value={value}
            onChange={onChange}
            getOptionLabel={option => option.title}
            autoHighlight
            filterOptions={filterOptions}
            disableOpenOnFocus
            //groupBy={option => option.parent}
            options={options}
            loading={!loaded}
            renderInput={params => (
                <TextField
                    {...params}
                    className={className}
                    label={label}
                    error={showError && error}
                    helperText={showError && error ? helperText : ''}
                    fullWidth
                    onChange={reFilterOnChange ? this.handleReloadChange : null}
                    value={inputValue}
                    variant="outlined"
                    InputLabelProps={{shrink: false}}
                    InputProps={{
                        ...params.InputProps,
                        style:{padding: 7},
                        endAdornment: (
                            <React.Fragment>
                                {!loaded ? <CircularProgress color="inherit" size={20} style={{position: 'absolute', right: 35}} /> : null}
                                {params.InputProps.endAdornment}
                            </React.Fragment>
                        ),
                    }}
                />
            )}
            {...rest}
        />
    }
}