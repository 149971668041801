import * as React from "react";
import colors from "../styles/colors";
import withStyles from "@material-ui/core/styles/withStyles";
import Dialog from "@material-ui/core/Dialog/Dialog";
import DialogContent from "@material-ui/core/DialogContent/DialogContent";
import Typography from "@material-ui/core/Typography/Typography";
import Button from "@material-ui/core/Button/Button";
import Grid from "@material-ui/core/Grid/Grid";
import IconButton from "@material-ui/core/IconButton/IconButton";
import {Clear} from "@material-ui/icons";
import {isMobile} from "react-device-detect";
import TextField from "@material-ui/core/TextField/TextField";

class ServicesResultPopup extends React.Component {

    state = {
        fName: {v: '', e: true, s: false, l: 'First Name', a: 'given-name'},
        lName: {v: '', e: true, s: false, l: 'Last Name', a: 'family-name'},
        email: {v: '', e: true, s: false, l: 'Email', a: 'email'},
        place: {v: '', e: true, s: false, l: 'Postcode or Town'},
    };

    setValue = name => e => {
        let o = {};
        o[name] = {v: e.target.value, e: !e.target.value.length, s: false, l: this.state[name].l, a: this.state[name].a};
        this.setState(o);
    };

    submit = async e => {
        e.preventDefault();
        const obj = {};
        let hasErr = false;
        Object.keys(this.state).forEach(k => {
            let t = this.state[k];
            if(t.e) {
                hasErr = true;
                obj[k] = {v: t.v, e: t.e, s: true, l: t.l, a: t.a};
            }
        });

        if(!hasErr) {
            const {fName, lName, email, place} = this.state;
            const {category} = this.props;
            const body = new FormData();
            body.append('subject', 'FORM SUBMITTED FROM LANDING PAGE');
            body.append('content', `First Name:\t\t\t${fName.v}\nLast Name:\t\t\t${lName.v}\nEmail:\t\t\t\t${email.v}\nPostcode or Town:\t${place.v}\n${category ? `Service:\t\t\t${category}` : ''}\n\nKeep rocking!`);
            const response = await fetch(`${process.env.REACT_APP_API_URL}feedback/lp`, {
                method: 'POST',
                body
            });
            await response.json();
            window.location.replace(process.env.PUBLIC_URL);
        } else
            this.setState(obj);
    };

    render() {
        const {isOpen, classes, handleModal, postCode} = this.props;
        const {place} = this.state;
        const path = `${process.env.PUBLIC_URL}/static/image`;

        if(postCode && !place.v) this.setState({place: {...place, v: postCode, e: false} });
        else if(place.v && postCode !== place.v) this.setState({place: {...place, v: postCode} });

        return <Dialog open={isOpen}
                       PaperProps={{ className: classes.root }}
                       onClose={handleModal(false)}
        >
            <Grid container
                  direction="row"
                  justify="flex-end"
                  alignItems="center"
                  className={classes.title}
            >
                <IconButton className={classes.closeButton} onClick={handleModal(false)}>
                    <Clear/>
                </IconButton>
            </Grid>
            <DialogContent className={classes.content}>
                <Grid container
                      direction="column"
                      justify="center"
                      alignItems="center"
                >
                    {
                        !isMobile ?
                            <img src={`${path}/img-statement.png`}
                                 srcSet={`${path}/img-statement.png, ${path}/img-statement@2x.png 2x, ${path}/img-statement@3x.png 3x`}
                                 alt="pin"
                                 className={classes.image}
                            />
                            : null
                    }
                    <Typography className={classes.sorry}>
                        { isMobile ? 'Oops – no services!' : 'Sorry, we do not have anyone in your area for this service at the moment.' }
                    </Typography>
                    <Typography className={classes.note}>
                        { isMobile ? 'We’ll get right on it and contact you when we have someone.' : 'Please leave your details and we will get back to you with an update.' }
                    </Typography>
                    <form onSubmit={this.submit} style={{textAlign: 'center'}}>
                        {
                            Object.keys(this.state).map(k => <TextField
                                                                key={k}
                                                                label={this.state[k].l}
                                                                value={this.state[k].v}
                                                                autoComplete={this.state[k].a || 'off'}
                                                                type={k==='email' ? 'email': 'text'}
                                                                onChange={this.setValue(k)}
                                                                error={this.state[k].e && this.state[k].s}
                                                                variant="outlined"
                                                                className={classes.input}
                                />
                            )
                        }
                        <Button type="submit" className={classes.appButton}>Submit</Button>
                    </form>
                </Grid>
            </DialogContent>
        </Dialog>
    }
}

const styles = {
    root: {
        width: 818,
        //height: 634,
        borderRadius: 30,
        overflow: 'hidden',
        height: 'fit-content'
    },
    title: {
        height: 65,
        backgroundColor: colors.theme,
        padding: 0,
    },
    content: {
        height: 'fit-content',
        paddingBottom: 16,
    },
    sorry: {
        fontFamily: 'Lato',
        fontSize: 22,
        fontWeight: 'bold',
        textAlign: 'center',
        color: colors.text,
        marginTop: isMobile ? 0 : 28,
        marginBottom: isMobile ? 0 : 28,
    },
    note: {
        fontFamily: 'Lato',
        fontSize: 18,
        textAlign: 'center',
        color: colors.alternateTextLight,
        marginBottom: isMobile ? 12 : 43
    },
    closeButton: {
        color: colors.background
    },
    appButton: {
        width: 228,
        height: 44,
        borderRadius: 20,
        boxShadow: '0px 6px 8.6px 0.5px rgba(72, 44, 139, 0.18)',
        backgroundColor: colors.theme,
        color: colors.alternateText,
        fontSize: 20,
        fontFamily: '\'Lato\', sans-serif',
        //textTransform: 'none',
        '&:hover': {
            backgroundColor: colors.alternateTheme,
            color: colors.theme
        }
    },
    input: {
        margin: '0 3px 10px 5px',
        '& .MuiInputBase-root': {
            borderRadius: 20,
            height: 44,
        },
        '& .MuiFormLabel-root': {
            transform: 'translate(14px, 15px) scale(1)',
        },
        '& .MuiFormLabel-root.Mui-focused, & .MuiFormLabel-filled': {
            transform: 'translate(14px, -6px) scale(0.75)',
        }
    },
    image: {
        width: '40%',
        maxWidth: 180
    },
    '@media only screen and (max-height: 740px)': {
        image: {
            display: 'none'
        }
    }
};

export default withStyles(styles)(ServicesResultPopup);