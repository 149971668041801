import * as React from "react";
import colors from "../styles/colors";
import withStyles from "@material-ui/core/styles/withStyles";
import Dialog from "@material-ui/core/Dialog/Dialog";
import DialogContent from "@material-ui/core/DialogContent/DialogContent";
import Typography from "@material-ui/core/Typography/Typography";
import Button from "@material-ui/core/Button/Button";
import Grid from "@material-ui/core/Grid/Grid";
import IconButton from "@material-ui/core/IconButton/IconButton";
import {Check, Clear} from "@material-ui/icons";
import {isMobile} from "react-device-detect";
import TextField from "@material-ui/core/TextField/TextField";
import ServiceComponent from "./ServiceComponent";
import CircularProgress from "@material-ui/core/CircularProgress/CircularProgress";

class ServicesResultPopup extends React.Component {

    state = {
        name: {v: '', e: true, s: false, l: 'Full Name', a: 'name'},
        email: {v: '', e: true, s: false, l: 'Email', m: 'Please provide correct email address!', a: 'email'},
        loaded: 0,
        loading: false
    };

    setValue = name => e => {
        let o = {loaded: 0};
        o[name] = {v: e.target.value, e: !e.target.value.length, s: false, l: this.state[name].l, a: this.state[name].a};
        this.setState(o);
    };

    submit = async e => {
        e.preventDefault();
        const {handleModal} = this.props;
        const obj = {};
        let hasErr = false;
        Object.keys(this.state).forEach(k => {
            let t = this.state[k];
            if(typeof t === 'object' && t.e) {
                hasErr = true;
                obj[k] = {v: t.v, e: t.e, s: true, l: t.l, m: t.m, a: t.a};
            }
        });

        if(!hasErr) {
            let links = ServiceComponent.getLink(null, null, null, true);
            const body = new FormData();
            body.append('name', this.state.name.v);
            body.append('email', this.state.email.v);
            body.append('android', links.android);
            body.append('ios', links.ios);
            this.setState({loading: true, loaded: false});
            const response = await fetch(`${process.env.REACT_APP_API_URL}mail/mobilelink`, {
                method: 'POST',
                body
            });
            const result = await response.json();

            const exitTimeout = () => setTimeout(() => {
                this.setState({
                    name: {v: '', e: true, s: false, l: 'Full Name'},
                    email: {v: '', e: true, s: false, l: 'Email', m: 'Please provide correct email address!'},
                    loaded: 0,
                });
                handleModal(false)();
            }, 2000);

            if(!result.error) {
                this.setState({ loading: false, loaded: 1 });
                exitTimeout();
            }
            else if(result.error.email)
                this.setState({loaded: 0, loading: false, email: {v: this.state.email.v, e: true, s: true, l: 'Email', m: 'Please provide correct email address!', a: 'email'}});
            else
                this.setState({loaded: -1, loading: false})

        } else
            this.setState(obj);
    };

    render() {
        const {isOpen, classes, handleModal} = this.props;
        const {loading, loaded} = this.state;

        return <Dialog open={isOpen}
                       PaperProps={{ className: classes.root }}
                       onClose={handleModal(false)}
        >
            <Grid container
                  direction="row"
                  justify="flex-end"
                  alignItems="center"
                  className={classes.title}
            >
                <IconButton className={classes.closeButton} onClick={handleModal(false)}>
                    <Clear/>
                </IconButton>
            </Grid>
            <DialogContent className={classes.content}>
                <Grid container
                      direction="column"
                      justify="center"
                      alignItems="center"
                >
                    <Typography className={classes.sorry}>
                        It’s great you’re interested!
                    </Typography>
                    <Typography className={classes.note}>
                        We note that you are on a desktop or tablet, The Togetherdom App is designed for use on your mobile, so please type your email address below so we can email you a link to get the App directly from your mobile.
                    </Typography>
                    <form onSubmit={this.submit} style={{textAlign: 'center'}}>
                        {
                            Object.keys(this.state).filter(k => typeof this.state[k] === 'object').map(k => <TextField
                                                                key={k}
                                                                label={this.state[k].l}
                                                                value={this.state[k].v}
                                                                autoComplete={this.state[k].a}
                                                                type={k==='email' ? 'email': 'text'}
                                                                onChange={this.setValue(k)}
                                                                error={this.state[k].e && this.state[k].s}
                                                                helperText={this.state[k].e && this.state[k].s ? this.state[k].m || '' : ''}
                                                                variant="outlined"
                                                                className={classes.input}
                                />
                            )
                        }
                        {
                            loaded === -1 ? <Typography style={{color: 'red'}}>Message was already sent to this email address!</Typography> : null
                        }
                        <Button type="submit" className={classes.appButton}>
                            {loaded===1 ? 'Thank You!' : 'Submit'}
                            {loading && <CircularProgress className={classes.icon} size={24} /> }
                            {loaded===1 ? <Check className={classes.icon} size={24} /> : null}
                        </Button>
                    </form>
                </Grid>
            </DialogContent>
        </Dialog>
    }
}

const styles = {
    root: {
        width: 818,
        //height: 634,
        borderRadius: 30,
        overflow: 'hidden',
        height: 'fit-content'
    },
    title: {
        height: 65,
        backgroundColor: colors.theme,
        padding: 0,
    },
    content: {
        height: 'fit-content',
        paddingBottom: 16,
    },
    sorry: {
        fontFamily: 'Lato',
        fontSize: 22,
        fontWeight: 'bold',
        textAlign: 'center',
        color: colors.text,
        marginTop: isMobile ? 0 : 28,
        marginBottom: isMobile ? 0 : 28,
    },
    note: {
        fontFamily: 'Lato',
        fontSize: 18,
        textAlign: 'center',
        color: colors.alternateTextLight,
        marginBottom: isMobile ? 12 : 43
    },
    closeButton: {
        color: colors.background
    },
    appButton: {
        width: 228,
        height: 44,
        borderRadius: 20,
        boxShadow: '0px 6px 8.6px 0.5px rgba(72, 44, 139, 0.18)',
        backgroundColor: colors.theme,
        color: colors.alternateText,
        fontSize: 20,
        fontFamily: '\'Lato\', sans-serif',
        //textTransform: 'none',
        '&:hover': {
            backgroundColor: colors.alternateTheme,
            color: colors.theme
        }
    },
    input: {
        margin: '0 3px 10px 5px',
        '& .MuiInputBase-root': {
            borderRadius: 20,
            height: 44,
        },
        '& .MuiFormLabel-root': {
            transform: 'translate(14px, 15px) scale(1)',
        },
        '& .MuiFormLabel-root.Mui-focused, & .MuiFormLabel-filled': {
            transform: 'translate(14px, -6px) scale(0.75)',
        }
    },
    icon: {
        marginLeft: 20,
        color: 'inherit !important'
    }
};

export default withStyles(styles)(ServicesResultPopup);