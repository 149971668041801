import React from 'react';
import withStyles from '@material-ui/core/styles/withStyles';
import {isMobile} from 'react-device-detect';

import colors from "./styles/colors";
import AsyncSelect from "./helpers/AsyncSelect";
import ServicesResultPopup from "./components/ServicesResultPopup";

import Grid from '@material-ui/core/Grid/Grid';
import Typography from '@material-ui/core/Typography/Typography';
import Box from '@material-ui/core/Box/Box';
import Button from '@material-ui/core/Button/Button';
import ServiceComponent from "./components/ServiceComponent";
import GridList from "@material-ui/core/GridList/GridList";
import {Search} from "@material-ui/icons";
import DesktopLinkPopup from "./components/DesktopLinkPopup";
import InfiniteScroll from 'react-infinite-scroll-component';

const w = window.innerWidth;
const smallStepsScreen = w < 470;

class App extends React.Component {

    constructor(props) {
        super(props);
        this.previewRef = React.createRef();
        this.serviceList = React.createRef();
        this.state = {
            category: null,
            paramCategory: null,
            location: null,
            paramLocation: null,
            imgWidth: 0,
            categories: [],
            services: [],
            openModal: false,
            openLinkModal: false,
            showLocationError: false,
            locationError: true,
            loaded: true,
            page: 1,
            maxPages: 1,
        };

        // console.log(ServiceComponent.getLink());
        // console.log(ServiceComponent.getLink(null, true));
    }

    componentDidMount() {
        const paramCategory = this.findGetParameter('category');
        const paramLocation = this.findGetParameter('location');
        if(paramCategory && paramLocation)
            this.setState({paramCategory, paramLocation, locationError: false, loaded: false});
    }

    setCategory = (e, val) => {
        this.setState({category: val, paramCategory: null});
    };

    setLocation = (e, val) => {
        this.setState({location: val, paramLocation: null, locationError: !val, showLocationError: false});
    };

    getImageSize = () => e => {
        this.setState({imgWidth: e.target.naturalWidth})
    };

    fetchServices = async (e, reset=false) => {
        if (e) e.preventDefault();
        console.log('reloading!');
        const {category, location, locationError, page} = this.state;
        if (locationError)
            this.setState({showLocationError: locationError});
        else {
            const response = await fetch(encodeURI(`${process.env.REACT_APP_API_URL}service/list?page=${reset ? 1 : page+1}&limit=10&filters[status]=1
                    ${`&filters${category ? `[category][]=${category.id}` : '='}`}
                    ${`&filters${location ? `[lat]=${location.lat}` : '='}`}
                    ${`&filters${location ? `[lng]=${location.lng}` : '='}`}
                `));
            const result = await response.json();

            if (result && result.data && result.data.services) {
                const services = result.data.services.filter(s => s.id > 0);
                const merged = reset ? services : [...this.state.services, ...services];
                this.setState({services:  merged, openModal: !merged.length, loaded: true, page: reset ? 1 : page+1, maxPages: result.data.paginate.pages});
                window.history.pushState(null, null, `${process.env.PUBLIC_URL}?category=${category ? category.url : 'all'}&location=${location.code}`);
                if (services.length) {
                    if(reset) this.serviceList.current.scrollTop = 0;
                    this.previewRef.current.scrollIntoView();
                }
            }
        }
    };

    handleModal = openModal => () => {
        this.setState({openModal})
    };

    handleLinkModal = openLinkModal => () => {
        this.setState({openLinkModal})
    };

    render() {
        const {classes} = this.props;
        const {location, imgWidth, services, openModal, openLinkModal, showLocationError, paramCategory, paramLocation, locationError, category, loaded, page, maxPages} = this.state;

        const path = `${process.env.PUBLIC_URL}/static/image`;
        const mod = isMobile ? 'mobile' : 'web';
        const src = `${path}/logo-top-${mod}.png`;
        const srcSet = `${path}/logo-top-${mod}.png, ${path}/logo-top-${mod}@2x.png 2x, ${path}/logo-top-${mod}@3x.png 3x`;

        if(location && (category || paramCategory === 'all') && !loaded) this.fetchServices(null, true);

        return (
            <div className={classes.root}>
                <ServicesResultPopup isOpen={openModal} handleModal={this.handleModal} postCode={location ? location.code : null} category={category ? category.title : ''} />
                {
                    !isMobile ? <DesktopLinkPopup isOpen={openLinkModal} handleModal={this.handleLinkModal} postCode={location ? location.code : null} place={category ? category.url : null} /> : null
                }
                <Grid container
                      direction="row"
                      justify="center"
                      alignItems="center"
                      className={classes.header}
                >
                    <img src={src}
                         srcSet={srcSet}
                         alt="logo-top"/>
                </Grid>
                <Grid
                    container
                    direction="column"
                    justify="flex-start"
                    alignItems="center"
                    className={`${classes.container} ${classes.welcome}`}
                >
                    <Typography className={classes.title}>
                        Welcome to Togetherdom!
                    </Typography>
                    <Typography className={classes.subtitle}>
                        Finding local people to do the jobs you need.
                    </Typography>

                    <form onSubmit={e => this.fetchServices(e, true)}>
                        <Grid
                            container
                            item
                            direction={isMobile ? "column" : "row"}
                            justify="center"
                            alignItems="center"
                            style={{marginTop: isMobile ? '31.7pt' : 74, marginBottom: services.length ? isMobile ? '45.3pt' : 130 : 0}}
                        >

                            <AsyncSelect className={`${classes.inputs} ${classes.inputFirst}`}
                                         onChange={this.setCategory}
                                         value={category}
                                         param={paramCategory}
                                         filter="url"
                                         method="category"
                                         parser={this.parseCategories}
                                         label="What service do you need?"
                                         helperText="Please choose a category first."
                            />

                            <AsyncSelect className={classes.inputs}
                                         onChange={this.setLocation}
                                         value={location}
                                         showError={showLocationError}
                                         error={locationError}
                                         param={paramLocation}
                                         filter="code"
                                         method="location/postcodes"
                                         reFilterOnChange
                                         parser={this.parseLocations}
                                         noOptionsText="Start typing to get the list of postcodes"
                                         label={<Grid container
                                                   direction="row"
                                                   justify="center"
                                                   alignItems="center">
                                                 <img src={`${path}/ic-pin.png`}
                                                      style={{marginRight: isMobile ? '20.7pt' : 47}}
                                                      srcSet={`${path}/ic-pin.png, ${path}/ic-pin@2x.png 2x, ${path}/ic-pin@3x.png 3x`}
                                                      alt="pin"/>
                                                 Enter your Town or Postcode
                                             </Grid>}
                                         helperText="Please choose your location first."
                            />

                            <Button type="submit" className={classes.confirmButton}>
                                Search
                                <Search style={{marginLeft: 10}} />
                            </Button>
                        </Grid>
                    </form>
                    {
                        services.length > 0 ?
                            <>
                                <Grid
                                    container
                                    item
                                    direction="row"
                                    justify="center"
                                    alignItems="center"
                                    ref={this.previewRef}
                                >
                                    <img className={classes.circleCheck}
                                         style={{marginRight: w > 400 ? 20 : 0}}
                                         src={`${path}/ic-check-circle.png`}
                                         srcSet={`${path}/ic-check-circle.png, ${path}/ic-check-circle@2x.png 2x, ${path}/ic-check-circle@3x.png 3x`}
                                         alt="circle-check"/>
                                    <Typography className={classes.title}>
                                        Local providers found!
                                    </Typography>
                                </Grid>
                                <Typography className={classes.subtitle}>
                                    Take a look at the great people below, tap to see more on the App and book.
                                </Typography>
                            </>
                         : <></>
                    }
                </Grid>
                <Grid
                    container
                    direction="column"
                    justify="flex-start"
                    alignItems="center"
                    className={`${classes.container} ${classes.dark} ${classes.phoneContainer}`}
                >
                    <Box className={classes.phoneCont}
                         style={{marginTop: imgWidth > w ? '-24.4%' : isMobile ? '-89.3pt' : -121}}>
                        <img className={classes.phoneCase}
                             src={`${path}/phone-with-shadows.png`}
                             srcSet={`${path}/phone-with-shadows.png, ${path}/phone-with-shadows@2x.png 2x, ${path}/phone-with-shadows@3x.png 3x`}
                             alt="phone-case"
                             onLoad={this.getImageSize()}
                        />
                        {
                            services.length === 0 ?
                                <img className={classes.phoneScreenEmpty}
                                     src={`${path}/app-screen.jpg`}
                                     srcSet={`${path}/app-screen.jpg, ${path}/app-screen@2x.jpg 2x, ${path}/app-screen@3x.jpg 3x`}
                                     alt="phone-screen"/>
                                :
                                <GridList id="phoneScreen" className={classes.phoneScreen} ref={this.serviceList} cols={1} cellHeight='auto'>
                                    <InfiniteScroll
                                        dataLength={services.length} //This is important field to render the next data
                                        next={this.fetchServices}
                                        scrollableTarget="phoneScreen"
                                        hasMore={page < maxPages}
                                        loader={<h4>Loading...</h4>}
                                        endMessage={<h4>That's all!</h4>}
                                        hasChildren={true}
                                        refreshFunction={this.refresh}
                                    >
                                        {
                                            services.map(s => <ServiceComponent key={s.id} {...s} zip={location ? location.code : null} category={category ? category.url : null} />)
                                        }
                                    </InfiniteScroll>
                                </GridList>
                        }
                    </Box>
                    {
                        isMobile ?
                            <Button className={classes.confirmButton} href={`${process.env.REACT_APP_DOMAIN_URL}portal/`} target="_blank" rel="noopener noreferrer">Go to App</Button>
                        :
                            <Button className={classes.appButton} onClick={this.handleLinkModal(true)}>Go to App</Button>
                    }
                </Grid>
                <Grid
                    container
                    direction="column"
                    justify="flex-start"
                    alignItems="center"
                    className={`${classes.container} ${classes.welcome}`}
                >
                    <Typography className={classes.title} style={{marginBottom: isMobile ? '36.3pt' : 70}}>
                        Easy as 1, 2, 3!
                    </Typography>
                    <Grid
                        container
                        item
                        direction={smallStepsScreen ? "column" : "row"}
                        justify={smallStepsScreen ? "center" : "flex-end"}
                        alignItems="center"
                        className={classes.steps}
                        style={{transform: isMobile ? 'translateX(-4.5%)' : 'translateX(-3%)'}}
                    >
                        <img src={`${path}/ic-1.png`}
                             srcSet={`${path}/ic-1.png, ${path}/ic-1@2x.png 2x, ${path}/ic-1@3x.png 3x`}
                             alt="ic-1"/>
                        <Typography className={classes.subtitle} style={{marginLeft: isMobile ? '12.7pt' : 51}}>
                            <strong>Choose</strong> a profile you like (to go to the app)
                        </Typography>
                    </Grid>
                    <Grid
                        container
                        item
                        direction={smallStepsScreen ? "column" : "row"}
                        justify={smallStepsScreen ? "center" : "flex-end"}
                        alignItems="center"
                        className={classes.steps}
                    >
                        <img src={`${path}/ic-2.png`}
                             srcSet={`${path}/ic-2.png, ${path}/ic-2@2x.png 2x, ${path}/ic-2@3x.png 3x`}
                             alt="ic-2"/>
                        <Typography className={classes.subtitle}
                                    style={smallStepsScreen ? {} : {marginLeft: isMobile ? '30.3pt' : 74}}>
                            <strong>Read</strong> their full profile <br/> (chat if you need)
                        </Typography>
                    </Grid>
                    <Grid
                        container
                        item
                        direction={smallStepsScreen ? "column" : "row"}
                        justify={smallStepsScreen ? "center" : "flex-end"}
                        alignItems="center"
                        className={classes.steps}
                        style={{marginBottom: isMobile ? '46.3pt' : 100}}
                    >
                        <img src={`${path}/ic-3.png`}
                             srcSet={`${path}/ic-3.png, ${path}/ic-3@2x.png 2x, ${path}/ic-3@3x.png 3x`}
                             alt="ic-3"/>
                        <Typography className={classes.subtitle}
                                    style={smallStepsScreen ? {} : {marginLeft: isMobile ? '28pt' : 71}}>
                            <strong>Book</strong> them in-App <br/> (rate and pay after the job)
                        </Typography>
                    </Grid>
                    <Typography className={classes.title}
                                style={{color: colors.theme, fontSize: isMobile ? '20pt' : 42}}>
                        Local, Easy, Trusted !
                    </Typography>
                    <Box className={classes.appBox}>
                        <Typography className={classes.title} style={{fontSize: isMobile ? '18.7pt' : 38}}>
                            Just want to see the App?
                        </Typography>
                        <Typography className={classes.title}
                                    style={{color: colors.theme, fontSize: isMobile ? '17.3pt' : 34}}>
                            It’s Free!
                        </Typography>
                        <Grid container
                              direction={w < 640 ? "column" : "row"}
                              justify="center"
                              alignItems="center">
                            {
                                isMobile ? <>
                                    <Button className={classes.storeButton}
                                            href={ServiceComponent.getLink()}
                                            target="_blank"
                                            rel="noopener noreferrer">
                                        <img src={`${path}/app-store-badge.png`}
                                             srcSet={`${path}/app-store-badge.png, ${path}/app-store-badge@2x.png 2x, ${path}/app-store-badge@3x.png 3x`}
                                             alt="app-store"/>
                                    </Button>
                                    <Button className={classes.storeButton}
                                            href={ServiceComponent.getLink()}
                                            target="_blank"
                                            rel="noopener noreferrer">
                                        <img src={`${path}/google-play-badge.png`}
                                             srcSet={`${path}/google-play-badge.png, ${path}/google-play-badge@2x.png 2x, ${path}/google-play-badge@3x.png 3x`}
                                             alt="g-store"/>
                                    </Button>
                                </> : <>
                                    <Button className={classes.storeButton}
                                            onClick={this.handleLinkModal(true)}>
                                        <img src={`${path}/app-store-badge.png`}
                                             srcSet={`${path}/app-store-badge.png, ${path}/app-store-badge@2x.png 2x, ${path}/app-store-badge@3x.png 3x`}
                                             alt="app-store"/>
                                    </Button>
                                    <Button className={classes.storeButton}
                                            onClick={this.handleLinkModal(true)}>
                                        <img src={`${path}/google-play-badge.png`}
                                             srcSet={`${path}/google-play-badge.png, ${path}/google-play-badge@2x.png 2x, ${path}/google-play-badge@3x.png 3x`}
                                             alt="g-store"/>
                                    </Button>
                                </>
                            }

                        </Grid>
                    </Box>
                </Grid>
                <Grid
                    container
                    direction="column"
                    justify="flex-start"
                    alignItems="center"
                    className={`${classes.container} ${classes.dark} ${classes.revolution}`}
                >
                    <Typography className={classes.title}>
                        Join the local service revolution!
                    </Typography>
                    <Grid container
                          direction={isMobile ? "column-reverse" : "row"}
                          justify="center"
                          alignItems="center"
                          style={{padding: '0 17pt'}}>
                        <Box className={classes.descText}>
                            <Typography>
                                We are all about connecting communities and making them stronger.
                            </Typography>
                            <br/>
                            <Typography>
                                Finding local services can be a nightmare:
                                Can you trust them? Are they reliable? <br/>
                                Will they do a good job?
                            </Typography>
                            <br/>
                            <Typography>
                                Just like Amazon and Airbnb, Togetherdom is a ‘marketplace’ App where you can find rated
                                and trusted local people. You can book them, then rate and pay them afterwards – All
                                through the App – Local, Easy, Trusted.
                            </Typography>
                        </Box>
                        <iframe className={classes.video}
                                title="Togetherdom introduction"
                                src="https://www.youtube.com/embed/3w6UQcmBWU8"
                                frameBorder="0"
                                allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
                                allowFullScreen/>
                    </Grid>
                    <Grid container
                          direction={w < 640 ? "column" : "row"}
                          justify="center"
                          alignItems="center"
                          style={{marginTop: isMobile ? '27.7pt' : 70}}>
                        {
                            isMobile ? <>
                                <Button className={classes.storeButton}
                                        href={ServiceComponent.getLink()}
                                        target="_blank"
                                        rel="noopener noreferrer">
                                    <img src={`${path}/app-store-badge.png`}
                                         srcSet={`${path}/app-store-badge.png, ${path}/app-store-badge@2x.png 2x, ${path}/app-store-badge@3x.png 3x`}
                                         alt="app-store"/>
                                </Button>
                                <Button className={classes.storeButton}
                                        href={ServiceComponent.getLink()}
                                        target="_blank"
                                        rel="noopener noreferrer">
                                    <img src={`${path}/google-play-badge.png`}
                                         srcSet={`${path}/google-play-badge.png, ${path}/google-play-badge@2x.png 2x, ${path}/google-play-badge@3x.png 3x`}
                                         alt="g-store"/>
                                </Button>
                            </> : <>
                                <Button className={classes.storeButton}
                                        onClick={this.handleLinkModal(true)}>
                                    <img src={`${path}/app-store-badge.png`}
                                         srcSet={`${path}/app-store-badge.png, ${path}/app-store-badge@2x.png 2x, ${path}/app-store-badge@3x.png 3x`}
                                         alt="app-store"/>
                                </Button>
                                <Button className={classes.storeButton}
                                        onClick={this.handleLinkModal(true)}>
                                    <img src={`${path}/google-play-badge.png`}
                                         srcSet={`${path}/google-play-badge.png, ${path}/google-play-badge@2x.png 2x, ${path}/google-play-badge@3x.png 3x`}
                                         alt="g-store"/>
                                </Button>
                            </>
                        }

                    </Grid>
                </Grid>
                <Box className={classes.break}/>
                <Grid
                    container
                    direction={isMobile ? "column" : "row"}
                    justify="center"
                    alignItems="center"
                    className={`${classes.container} ${classes.dark} ${classes.footer}`}
                >
                    {
                        isMobile || w < 1160 ?
                            <>
                                <Grid
                                    container
                                    direction="row"
                                    justify="center"
                                    alignItems="center"
                                    style={{marginBottom: '16.3pt'}}
                                >
                                    <Button
                                        className={`${classes.footerButton} ${classes.learnMore} ${classes.secondaryColor}`}
                                        variant="outlined"
                                        href={`${process.env.REACT_APP_DOMAIN_URL}need-a-service-or-a-job-doing/`}
                                        target="_blank"
                                        rel="noopener noreferrer">
                                        Learn more
                                    </Button>
                                    <Button
                                        className={`${classes.footerButton} ${classes.provideService} ${classes.secondaryColor}`}
                                        variant="outlined"
                                        href={`${process.env.REACT_APP_DOMAIN_URL}want-to-provide-a-service-or-do-a-job/`}
                                        target="_blank"
                                        rel="noopener noreferrer">
                                        Provide a service
                                    </Button>
                                </Grid>
                                <Grid
                                    container
                                    direction="row"
                                    justify="space-around"
                                    alignItems="center"
                                >
                                    <img src={`${path}/logo-footer.png`}
                                         srcSet={`${path}/logo-footer.png, ${path}/logo-footer@2x.png 2x, ${path}/logo-footer@3x.png 3x`}
                                         alt="logo-footer"/>
                                    <Grid container
                                          item
                                          direction="column"
                                          justify="center"
                                          alignItems="flex-end"
                                          style={{width: 'fit-content'}}
                                    >
                                        <Typography className={classes.copyright}>
                                            © 2020 Togetherdom Ltd. All Rights Reserved
                                        </Typography>
                                        <Grid container
                                              item
                                              direction="row"
                                              justify="flex-end"
                                              alignItems="center"
                                        >
                                            <a href={process.env.REACT_APP_DOMAIN_URL}
                                               target="_blank"
                                               rel="noopener noreferrer">
                                                <Typography className={`${classes.copyright} ${classes.footerLink}`} style={{marginRight: isMobile ? '6pt' : 10}}>Main Site</Typography>
                                            </a>
                                            <a href={`${process.env.REACT_APP_DOMAIN_URL}contact-2/`}
                                               target="_blank"
                                               rel="noopener noreferrer">
                                                <Typography className={`${classes.copyright} ${classes.footerLink}`}>Contact us</Typography>
                                            </a>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </> : <>
                                <img src={`${path}/logo-footer.png`}
                                     srcSet={`${path}/logo-footer.png, ${path}/logo-footer@2x.png 2x, ${path}/logo-footer@3x.png 3x`}
                                     alt="log-footer"/>
                                <Box className={classes.footerButtons}>
                                    <Button className={`${classes.footerButton} ${classes.learnMore}`}
                                            href={`${process.env.REACT_APP_DOMAIN_URL}main/need-a-service-or-a-job-doing/`}
                                            target="_blank"
                                            rel="noopener noreferrer">
                                        Learn more
                                    </Button>
                                    <Button
                                        className={`${classes.provideService} ${classes.secondaryColor}`}
                                        variant="outlined"
                                        href={`${process.env.REACT_APP_DOMAIN_URL}main/want-to-provide-a-service-or-do-a-job/`}
                                        target="_blank"
                                        rel="noopener noreferrer">
                                        Provide a service
                                    </Button>
                                </Box>
                                <Grid container
                                      item
                                      direction="column"
                                      justify="center"
                                      alignItems="flex-end"
                                      style={{width: 'fit-content'}}
                                >
                                    <Typography className={classes.copyright}>
                                        © 2020 Togetherdom Ltd. All Rights Reserved
                                    </Typography>
                                    <Grid container
                                          item
                                          direction="row"
                                          justify="flex-end"
                                          alignItems="center"
                                    >
                                        <a href={`${process.env.REACT_APP_DOMAIN_URL}main/`}
                                           target="_blank"
                                           rel="noopener noreferrer">
                                            <Typography className={`${classes.copyright} ${classes.footerLink}`} style={{marginRight: isMobile ? '6pt' : 10}}>Main Site</Typography>
                                        </a>
                                        <a href={`${process.env.REACT_APP_DOMAIN_URL}main/contact-2/`}
                                           target="_blank"
                                           rel="noopener noreferrer">
                                            <Typography className={`${classes.copyright} ${classes.footerLink}`}>Contact us</Typography>
                                        </a>
                                    </Grid>
                                </Grid>
                            </>
                    }

                </Grid>
            </div>
        );
    }

    findGetParameter(parameterName) {
        let result = null,
            tmp = [];
        window.location.search
            .substr(1)
            .split("&")
            .forEach(function (item) {
                tmp = item.split("=");
                if (tmp[0] === parameterName) result = decodeURIComponent(tmp[1]);
            });
        return result;
    }

    parseCategories(data) {
        return data.category.filter(a => a.level===3).map(a => {
            if(a.title.indexOf("Other") >= 0) a.title = `${a.title} ${data.category.find(b => a.parent === b.id).title}`;
            return a;
        }).sort((a,b) => a.title > b.title ? 1 : -1);
    }

    parseLocations(data) {
        return data.sort((a, b) => {
            return a.name === b.name ? (a.code > b.code ? 1 : -1) : (a.city > b.city ? 1 : -1)
        }).map(a => {
            a.title = a.name ? `${a.name} (${a.code})` : (a.code);
            return a
        });
    }
}

const style = {
    root: {

    },
    header: {
        backgroundColor: colors.theme,
        height: isMobile ? '87pt' : 243,
    },
    footer: {
        minHeight: isMobile ? '120.3pt' : 147,
        paddingBottom: isMobile ? '12pt' : 20,
        paddingTop: isMobile ? '6pt' : 10,
    },
    phoneContainer: {
        paddingBottom: isMobile ? '29.3pt' : 52,
    },
    container: {
        textAlign: 'center',
    },
    welcome: {
        paddingTop: isMobile ? '46.7pt' : 113,
        paddingBottom: isMobile ? '84.3pt' : 208,
    },
    revolution: {
        paddingTop: isMobile ? '30.7pt' : 97,
        paddingBottom: isMobile ? '33.3pt' : 63,
    },
    desc: {
        paddingTop: isMobile ? '43.3pt' : 111,
        paddingBottom: isMobile ? '84.3pt' : 208,
    },
    dark: {
        backgroundColor: colors.darker
    },
    title: {
        fontSize: isMobile ? '23.3pt' : 46,
        fontWeight: 'bold',
    },
    subtitle: {
        fontSize: isMobile ? '13.7pt' : 28,
    },
    halfWidth: {
        maxWidth: '50%',
    },
    phoneScreenEmpty: {
        position: 'absolute',
        top: '14.9%',
        right: '19.9%',
        maxWidth: '59.8%',
    },
    phoneScreen: {
        margin: '0 !important',
        backgroundColor: colors.background,
        overflowX: 'hidden',
        position: 'absolute',
        top: '14.9%',
        right: '19.9%',
        width: '59.7%',
        height: '67%',
        display: 'block',
    },
    phoneCase: {
        maxWidth: '100vw',
        transform: 'translateX(-6.21%)',
    },
    phoneCont: {
        position: 'relative',
    },
    circleCheck: {
        transform: 'translateY(4%)',
        maxHeight: isMobile ? '27.3pt' : 'initial',
    },
    appButton: {
        width: 273,
        height: 73,
        backgroundColor: colors.theme,
        color: colors.alternateText,
        fontSize: isMobile ? '15.7pt' : 31,
        fontFamily: '\'Lato\', sans-serif',
        fontWeight: 'bold',
        borderRadius: 42,
        '&:hover': {
            backgroundColor: colors.alternateTheme,
            color: colors.theme
        }
    },
    inputs: {
        height: isMobile ? '38pt' : 67,
        width: w < 360 && isMobile ? '220pt' : (isMobile ? '250.3pt' : 438),
        border: `2px solid ${colors.theme}`,
        margin: isMobile ? '0 0 18.7pt 0' : 'initial',
        display: 'block',
        borderRadius: 42,
        '& .MuiSelect-root': {
            backgroundColor: 'transparent !important',
        },
        outline: 'none !important',
        '& fieldset': {
            border: 'none !important'
        },
        '& > div': {
            height: '100%'
        },
        '&, & label': {
            color: colors.text,
            fontFamily: '\'Lato\', sans-serif',
            fontSize: `${isMobile ? '12pt' : 19} !important`,
            fontWeight: 'bold',
        },
        '& label': {
            transform: 'translate(-50%, -50%)',
            left: '50%',
            top: '50%',
            width: '100%',
        },
        '& label.Mui-focused': {
            color: `${colors.theme} !important`,
            display: 'none',
        },
        '& label.MuiFormLabel-filled': {
           display: 'none',
        },
        '& .MuiInputBase-root': {
            padding: 8,
            width: '100%',
        },
        '& input': {
            textAlign: 'center'
        },
        '& .MuiAutocomplete-endAdornment': {
            top: 'initial',
        }
    },
    inputFirst: {
        margin: isMobile ? '0 0 18.7pt 0' : '0 22px 0 0',
    },
    '@media only screen and (max-width: 920px)': {
        inputFirst: {
            margin: isMobile ? '0 0 18.7pt 0' : '0 22px 22px 22px',
        },
        inputs: {
            margin: isMobile ? '0 0 18.7pt 0' : '0 22px  22px 22px',
        }
    },
    confirmButton: {
        height: isMobile ? '38pt' : 67,
        width: w < 360 && isMobile ? '220pt' : (isMobile ? '250.3pt' : 438),
        margin: isMobile ? 0 : '22px calc(50vw - 219px) 0 calc(50vw - 219px)',
        borderRadius: 30,
        backgroundColor: colors.theme,
        color: colors.background,
        '&:hover': {
            backgroundColor: colors.alternateTheme,
            color: colors.theme
        }
    },
    steps: {
        marginBottom: isMobile ? '26pt' : 54,
        width: 'fit-content',
        '& p': {
            textAlign: smallStepsScreen ? 'center' : 'left',
            width: isMobile ? '156.7pt' : 338,
            marginTop: smallStepsScreen ? '10px' : '',

        }
    },
    appBox: {
        maxWidth: '90vw',
        width: isMobile ? '328.7pt' : 933,
        padding: isMobile ? '12.7pt' : 26,
        border: `2px solid ${colors.theme}`,
        borderRadius: 22,
        boxSizing: 'border-box',
        boxShadow: '0px 5px 16.9px 1.1px rgba(72, 40, 126, 0.13)',
        marginTop: isMobile ? '38pt' : 86
    },
    descText: {
        margin: isMobile ? '24.3pt 0 0 0' : '22px 47px 34px 0',
        '& > p': {
            fontSize: isMobile ? '13.3pt' : 23,
            fontWeight: 'bold',
            textAlign: isMobile ? 'center' :'left',
            color: '#555555',
            maxWidth: isMobile ? '326.3pt' : 475,
            maxHeight: isMobile ? '188.3pt' : 321,
            lineHeight: '25px',
        }
    },
    '@media only screen and (max-width: 1256px)': {
        descText: {
            '& > p': {
                textAlign: 'center'
            }
        }
    },
    storeButton: {
        padding: 0,
        '&:first-child': {
            margin: isMobile ? '0 20pt 0 0' : '0 40px 0 0',
        }
    },
    '@media only screen and (max-width: 640px)': {
        storeButton: {
            margin: isMobile ? '0 15pt' : '0 20px',
            '&:first-child': {
                margin: isMobile ? '0 15pt 10pt 15pt' : '0 20px 0 20px',
            }
        },
    },
    video: {
        height: isMobile ? 'calc(315 / 560 * (100vw - 34pt))' : 'calc(315 / 560 * (100vw - 60px))',
        width: isMobile ? 'calc(100vw - 34pt)' : 'calc(100vw - 60px)',
        maxWidth: isMobile ? '338pt' : 669,
        maxHeight: isMobile ? '184.7pt' : 377,
    },
    break: {
        width: '100%',
        height: 2,
        opacity: 0.27,
        backgroundColor: colors.breakLine,
    },
    learnMore: {
        width: isMobile ? '92pt' : 158,
        height: isMobile ? '38pt' : 60,
        backgroundColor: colors.theme,
        padding: '5px 10px',
        fontSize: isMobile ? '8.7pt' : 14,
        color: colors.alternateText,
        marginRight: isMobile ? '23pt' : 39,
        '&:hover': {
            backgroundColor: colors.alternateTheme,
            color: colors.theme
        }
    },
    provideService: {
        width: isMobile ? '109pt' : 194,
        height: isMobile ? '38pt' : 60,
        padding: '5px 10px',
        fontSize: isMobile ? '8.7pt' : 14,
    },
    secondaryColor: {
        borderColor: colors.theme,
        color: colors.theme,
        backgroundColor: 'transparent',
        '&:hover': {
            backgroundColor: colors.alternateTheme,
            color: colors.theme
        }
    },
    copyright: {
        fontFamily: '\'Lato\', sans-serif',
        fontSize: isMobile ? '10pt' : 14,
        lineHeight: 1.36,
        color: colors.copyright,
        textAlign: 'right',
        maxWidth: isMobile ? '130pt' : 163,
        marginBottom: isMobile ? '6pt' : 10,
    },
    footerLink: {
        color: colors.theme,
        textDecoration: 'underline',
        marginBottom: 0,
    },
    footerButtons: {
        marginRight: 191,
        marginLeft: 287
    }
};

export default withStyles(style)(App);
