import * as React from "react";
import withStyles from "@material-ui/core/styles/withStyles";
import Rating from '@material-ui/lab/Rating';
import Typography from "@material-ui/core/Typography/Typography";
import Box from "@material-ui/core/Box/Box";
import Avatar from "@material-ui/core/Avatar/Avatar";
import GridListTile from "@material-ui/core/GridListTile/GridListTile";
import Grid from "@material-ui/core/Grid/Grid";
import colors from "../styles/colors";

class ServiceComponent extends React.Component {



    render() {
        const {classes, avatar, title, user, reviews, experience, rate, checked, price, currency, price_type, service_url, zip, category} = this.props;
        const path = `${process.env.PUBLIC_URL}/static/image/screen`;

        console.log(category);

        return (
            <GridListTile className={classes.root}>
                <a href={ServiceComponent.getLink(service_url, zip, category)}
                   target="_blank"
                   rel="noopener noreferrer">
                    <Box className={classes.fullWidth}>
                <Grid container
                      direction="row"
                      justify="flex-start"
                      alignItems="center"
                >
                    <Avatar alt={user} src={avatar} className={classes.avatar} />
                    <Grid container
                          item
                          direction="column"
                          justify="center"
                          alignItems="flex-start"
                          className={classes.titleContainer}
                    >
                        <Typography className={classes.title}>{title}</Typography>
                        <Typography className={classes.name}>{`by ${user}`}</Typography>
                    </Grid>
                    {
                        checked ? <img className={classes.circleCheck}
                                       src={`${path}/check.png`}
                                       srcSet={`${path}/check.png, ${path}/check@2x.png 2x, ${path}/check@3x.png 3x`}
                                       alt="circle-check"/>
                            : <></>
                    }
                    <Grid container
                          item
                          direction="column"
                          justify="center"
                          alignItems="flex-end"
                          className={classes.priceContainer}
                    >
                        <img className={classes.background}
                             alt="ellipse"
                             src={`${path}/elipse.png`}
                             srcSet={`${path}/elipse.png, ${path}/elipse@2x.png 2x, ${path}/elipse@3x.png 3x`} />
                        <Typography className={classes.price}>{`${currency === 'GBP' ? '£' : ''}${parseFloat(price).toFixed(2)}`}</Typography>
                        {
                            price_type === 'hourly' ? <Typography className={classes.priceType}>{'p/h'}</Typography> : <></>
                        }
                    </Grid>
                </Grid>
                <Grid container
                      direction="row"
                      justify="flex-start"
                      alignItems="center"
                >
                    <Rating name="read-only" value={rate} readOnly className={classes.rating} />
                    <Typography component="legend" className={classes.rating}>
                        {`${reviews} review${reviews > 1 ? "s" : ""}`}
                    </Typography>
                </Grid>
                {
                    experience && experience.length ? <Typography className={classes.desc}>{experience}</Typography> : <></>
                }
                    </Box>
                </a>
            </GridListTile>
        )
    }

    static getLink(service=null, zip=null, category=null, twoLinks=false) {
        return twoLinks ?
            {
                android: `https://${process.env.REACT_APP_FIREBASE_DL_DOMAIN}/?link=${ServiceComponent.encodeURI(process.env.REACT_APP_FIREBASE_LINK_PREFIX)}?apn=${ServiceComponent.encodeURI(process.env.REACT_APP_ANDROID_APP_PACKAGE_NAME)}`,
                ios: `https://${process.env.REACT_APP_FIREBASE_DL_DOMAIN}/?link=${ServiceComponent.encodeURI(process.env.REACT_APP_FIREBASE_LINK_PREFIX)}?ibi=${ServiceComponent.encodeURI(process.env.REACT_APP_IOS_APP_PACKAGE_NAME)}&isi=${ServiceComponent.encodeURI(process.env.REACT_APP_IOS_APP_APPSTORE_ID)}&imv=${ServiceComponent.encodeURI(process.env.REACT_APP_IOS_MIN_DL_APP_VERSION)}`,
            }
            :
            `https://${process.env.REACT_APP_FIREBASE_DL_DOMAIN}/?link=${ServiceComponent.encodeURI(process.env.REACT_APP_FIREBASE_LINK_PREFIX)}${service ? `service/${service}/category/${category || 'all'}${zip ? `/zip/${zip}` : ''}&` : '?'}ibi=${ServiceComponent.encodeURI(process.env.REACT_APP_IOS_APP_PACKAGE_NAME)}&apn=${ServiceComponent.encodeURI(process.env.REACT_APP_ANDROID_APP_PACKAGE_NAME)}&isi=${ServiceComponent.encodeURI(process.env.REACT_APP_IOS_APP_APPSTORE_ID)}&imv=${ServiceComponent.encodeURI(process.env.REACT_APP_IOS_MIN_DL_APP_VERSION)}`
            ;
    }

    static encodeURI(s) {
        return s.replace(/\./g, '%2E').replace(/:/g, '%3A').replace(/\//g, '%2F')
    }
}

const styles = {
    root: {
        width: '100%',
        height: 'fit-content',
        borderBottom: `1px solid ${colors.breakLine}`,

    },
    avatar: {
        margin: '10px 6px 0 10px',
    },
    titleContainer: {
        maxWidth: '45%',
    },
    title: {
        fontSize: 16,
        lineHeight: .9,
        fontWeight: 600,
        fontFamily: '\'Lato\', sans-serif',
        textAlign: 'left',
        marginTop: 9
    },
    name: {
        fontSize: 14,
        lineHeight: .9,
        fontFamily: '\'Lato\', sans-serif',
        textAlign: 'left',
        color: colors.alternateTextDark,
        marginLeft: 3,
        marginTop: 7,
    },
    circleCheck: {
        position: 'absolute',
        right: 73,
    },
    priceContainer: {
        width: 'fit-content',
        height: 'fit-content',
        position: 'absolute',
        right: 0,
        top: 0,
        '& > p': {
            color: colors.theme,
            lineHeight: 1,
            zIndex: 1,
            fontWeight: 'bold',
            fontFamily: '\'Lato\', sans-serif',
        },
    },
    background: {
        position: 'absolute',
        zIndex: 0,
        top: 0,
        right: -1,
    },
    price: {
        fontSize: 14,
        marginTop: 8,
        marginRight: 3
    },
    priceType: {
        fontSize: 8,
        marginTop: 4,
        marginRight: 15,
    },
    rating: {
        color: colors.theme,
        fontSize: 11,
        margin: '13px 0 5px 10px',
    },
    desc: {
        fontSize: 12,
        textAlign: 'left',
        fontWeight: '600',
        lineHeight: 'normal',
        fontFamily: '\'Lato\', sans-serif',
        margin: 6
    },
    fullWidth: {
        width: '100%',
        height: '100%',
    }
};

export default withStyles(styles)(ServiceComponent);