export default {
    theme: '#48287e',
    alternateTheme: '#dad4e5',
    background: '#ffffff',
    darker: '#f1f3f9',
    text: '#000000',
    alternateText: '#ffffff',
    alternateTextDark: '#757575',
    alternateTextLight: '#555555',
    breakLine: '#bcbdc4',
    copyright: '#8d90ab',
};